<template>
  <div class="popup" v-bind:class="{ act: show, [mainClass]: mainClass }">
    <div class="popup__wrap" v-bind:class="wrapClass" @click.stop>
      <!-- <div class="popup-nav">
            <button type="button" class="popup__close btn--border popup-nav__btn-back" @click="$emit('hide')">Назад</button>
            <div class="hamburger">
                <button class="hamburger__btn"></button>
            </div>
        </div> -->
      <div class="popup__top">
        <div class="popup__top-wrap">
          <h2 class="popup-verify__title">{{ title }}</h2>
          <span class="popup-verify__desrc">
            {{ description }}
          </span>
        </div>
        <button class="popup2__close" @click="$emit('hide')">
          <svg class="icon-close-btn popup-theses__icon">
            <use xlink:href="@main/assets/sprite.svg#close-btn"></use>
          </svg>
        </button>
      </div>
      <div :class="contentClass">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    description: String,
    wrapClass: {
      type: String,
      default: '',
    },
    contentClass: {
      type: String,
      default: '',
    },
    mainClass: {
      type: String,
      default: 'popup-instruction',
    },
    show: Boolean,
  },
};
</script>
