<template>
  <modal
    :show="showAbuseModal"
    @hide="hide"
    title="Пожаловаться"
    mainClass="popup-report"
    contentClass="popup__content popup-report__content"
    wrapClass="popup-report__wrap"
  >
    <p class="popup-report__text">
      Приложите скриншоты, доказывающие ваши слова. Если вы не приложите достаточных доказательств,
      то мы не сможем разрешить вашу ситуацию.
    </p>
    <form class="popup-report__form" @submit.prevent="submit" novalidate="true">
      <h3 class="popup-report__title">Выберите причину</h3>

      <div
        class="popup-report__radio"
        v-bind:class="{ error: !$v.reportMode.required && $v.reportMode.$dirty }"
      >
        <div class="popup-report__row radio">
          <input
            class="radio__input"
            value="0"
            type="radio"
            v-model="$v.reportMode.$model"
            id="report1"
            name="radio"
          />
          <label for="report1" class="radio__label">Блогер не выложил рекламу на мой аккаунт</label>
        </div>
        <div class="popup-report__row radio">
          <input
            class="radio__input"
            value="1"
            type="radio"
            v-model="$v.reportMode.$model"
            id="report2"
            name="radio"
          />
          <label for="report2" class="radio__label"
            >Блогер обманул меня со статистикой своего аккаунта, у меня есть доказательства</label
          >
        </div>
        <div class="popup-report__row radio">
          <input
            class="radio__input"
            value="2"
            type="radio"
            v-model="$v.reportMode.$model"
            id="report3"
            name="radio"
          />
          <label for="report3" class="radio__label">Иная причина</label>
        </div>
        <span class="error-msg">Выберите один вариант, чтобы продолжить</span>
      </div>

      <h3 class="popup-report__title">Текст жалобы</h3>
      <div
        class="popup-report__textarea textarea"
        v-bind:class="{ error: !$v.text.required && $v.text.$dirty }"
        :data-symbol="textCaption"
      >
        <textarea v-model="text" maxlength="500"></textarea>
      </div>

      <div class="popup-report__flex">
        <div class="popup-report__flex-item">
          <label class="label popup-report__label" for="email2"
            >Ваш e-mail для обратной связи</label
          >
          <div class="popup-report__input" v-bind:class="{ error: $v.email.$anyError }">
            <div class="input">
              <input type="email" v-model.trim="$v.email.$model" id="email2" />
            </div>
            <span class="error-msg">Заполните это поле, чтобы продолжить</span>
          </div>
        </div>

        <div class="popup-report__flex-item">
          <span class="label popup-report__label">Загрузите скриншоты</span>
          <SelectFiles
            :files="$v.files.$model"
            multiple
            @addFile="
              (file) => {
                files.push(file);
              }
            "
            :error="!$v.files.required && $v.files.$dirty"
          />
        </div>
      </div>

      <div
        class="checkbox popup-report__checkbox"
        v-bind:class="{ error: !$v.agree.must && $v.agree.$dirty }"
      >
        <input id="checkbox2" v-model="$v.agree.$model" type="checkbox" class="checkbox__input" />
        <label for="checkbox2" class="checkbox__label row">
          Я согласен с
          <a href="https://easyprbot.com/privacy/" target="_blank" class="checkbox__link"
            >Политикой конфидециальности</a
          >
        </label>
        <span class="error-msg">Выберите чекбокс, чтобы продолжить</span>
      </div>
      <button type="submit" class="btn--orange js-btnPopup" data-popup="popupReportError">
        Отправить жалобу
      </button>
    </form>
  </modal>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';
import SelectFiles from '@mp/components/SelectFiles.vue';
import { required, email } from 'vuelidate/lib/validators/';
import { mapState, mapMutations } from 'vuex';

import { createAbuse } from '@mp/api/moderation';

const must = (value) => value;

export default {
  props: {},
  components: {
    Modal,
    SelectFiles,
  },
  data() {
    return {
      text: '',
      agree: false,
      reportMode: null,
      email: '',
      files: [],
    };
  },
  validations: {
    agree: {
      must,
    },
    text: {
      required,
    },
    email: {
      required,
      email,
    },
    reportMode: {
      required,
    },
    files: {
      required,
    },
  },
  computed: {
    ...mapState('mutualpr', ['showAbuseModal', 'abusedProfileID']),
    textCaption() {
      return `${this.text.length}/500`;
    },
  },
  methods: {
    ...mapMutations('mutualpr', ['hideAbuseModal']),
    ...mapMutations('notifications', ['setaActivePopups']),

    processFiles(event) {
      this.files = [];
      for (let i = 0; i < event.target.files.length; i += 1) {
        this.files.push(event.target.files[i]);
      }
    },

    hide() {
      this.text = '';
      this.agree = false;
      this.reportMode = null;
      this.email = '';
      this.files = [];
      this.$v.$reset();
      this.hideAbuseModal();
    },
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const result = await createAbuse(
          this.abusedProfileID,
          this.reportMode,
          this.text,
          this.email,
          this.files
        );
        if (result.success) {
          this.setaActivePopups([{ title: 'Жалоба отправлена' }]);
        }
        this.hide();
      }
    },
  },
};
</script>
<style scoped>
#email2 {
  width: 100%;
}
.row {
  display: table-cell;
}
.checkbox__label {
  display: flex;
  align-items: center;
}
.popup-report__checkbox {
  padding: 0 16px;
}
.error {
  border: none !important;
}
</style>
