<template>
  <div v-if="model">
    <div class="popup-verify-theme">
      <form @submit.prevent="() => save()">
        <p class="popup-verify-theme__title">Укажите 1-3 темы вашего аккаунта</p>
        <div class="dropdown popup-verify-theme__dropdown">
          <multiselect
            v-model="model.blogger_profile.themes"
            :options="tags"
            :multiple="true"
            :max="3"
            placeholder="Выберите из списка"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            label="name"
            track-by="id"
          >
            <template v-slot:maxElements>Максимум 3!</template>
          </multiselect>
        </div>

        <div class="popup-verify-theme__title-wrap">
          <p class="popup-verify-theme__title">Охваты сторис</p>
          <button
            @click="showFillExample = true"
            type="button"
            class="link-blue js-btnPopup"
            data-popup="popupThemeExmpl"
          >
            Пример заполнения
          </button>
        </div>
        <p class="popup-verify-theme__text">
          Укажите минимальное и максимальное значение охватов в сторис за последние 14 дней.
        </p>

        <div class="popup-verify-theme__flex">
          <div class="popup-verify-theme__flex-item">
            <label for="themeInput1" class="popup-verify-theme__label">Минимум</label>
            <input
              type="number"
              id="themeInput1"
              class="input popup-verify-theme__input"
              v-model="model.blogger_profile.coverage_min"
              :min="100"
              required
              :max="model.blogger_profile.coverage_max"
            />
          </div>
          <div class="popup-verify-theme__flex-item">
            <label for="themeInput2" class="popup-verify-theme__label">Максимум</label>
            <input
              type="number"
              id="themeInput2"
              class="input popup-verify-theme__input"
              v-model="model.blogger_profile.coverage_max"
              required
              :max="2000000"
              :min="model.blogger_profile.coverage_min"
            />
          </div>
        </div>

        <p class="popup-verify-theme__title">Тезисы</p>
        <div
          class="popup-verify-prem__textarea textarea"
          :data-symbol="
            model.blogger_profile.theses ? model.blogger_profile.theses.length : 0 + '/300'
          "
        >
          <textarea required v-model.trim="model.blogger_profile.theses" maxlength="300"></textarea>
          <div class="popup-verify-prem-exmpl">
            <div class="new-line">
              <p>Например: Мой блог про:</p>
              <p>Медитации и силу подсознания</p>
              <p>Психологическую сторону успеха и денег</p>
              <p>Обзоры и анти-обзоры на книги</p>
            </div>
            <div class="new-line">
              <p>Последние темы про:</p>
              <p>- Медитации для новичков</p>
              <p>- Как медитации влияют на мозг</p>
              <p>- Как подтолкнуть партнера к работе над собой</p>
              <p>- Как заставить кризисы работать на пользу</p>
              <p>- Как развиваться без книг и тренингов</p>
            </div>
          </div>
        </div>
        <div class="reg_sett-btnblock">
          <button @click.prevent="() => $emit('back')" class="btn--border">Назад</button>
          <button type="submit" class="btn--orange">Далее</button>
        </div>
      </form>
    </div>
    <FillExample :open="showFillExample" @close="showFillExample = false" />
  </div>
</template>

<script>
import FillExample from '@main/components/reusable/modals/FillExample.vue';
import ProfileCRUMixin from '@main/mixins/ProfileCRUMixin.vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
    FillExample,
  },
  mixins: [ProfileCRUMixin],
  async mounted() {
    await this.initProfile();
  },
  data: () => ({
    showFillExample: false,
  }),
  computed: {
    ...mapState(['tags', 'currentUser']),
  },
  methods: {
    async save() {
      await this.patchProfile({ showMessage: false });
      this.$emit('complete');
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.popup-verify-theme {
  overflow: auto;
}
.btn--orange {
  margin-top: 0px;
  max-width: 157px;
  padding: 8.5px 15px;
}
.btn--border {
  max-width: 157px;
  height: 36px;
  padding: 8.5px 15px;
}
.reg_sett-btnblock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 36px;
}
</style>
