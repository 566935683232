<template>
  <div class="popup-report__load" v-bind:class="{ error: error }">
    <div class="popup-report__load-wrap">
      <input
        type="file"
        :multiple="multiple"
        :accept="accept"
        class="popup-report__load-input"
        @change="processFiles($event)"
      />
      <button class="btn--blue">{{ buttonText }}</button>
    </div>
    <span class="popup-report__load-label" v-if="files.length === 0">Файл не выбран</span>
    <span class="popup-report__load-label" v-else-if="multiple"
      >Выбрано {{ files.length }} файлов</span
    >
    <span class="popup-report__load-label" v-else>Файл выбран</span>
    <span class="error-msg">Заполните это поле, чтобы продолжить</span>
  </div>
</template>
<script>
export default {
  props: {
    files: {
      type: Array,
      required: true,
    },
    buttonText: {
      type: String,
      default: 'Загрузить скриншот',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: 'image/*',
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    processFiles(event) {
      for (let i = 0; i < event.target.files.length; i += 1) {
        this.$emit('addFile', event.target.files[i]);
      }
    },
  },
  watch: {
    files(val) {
      console.log(val);
    },
  },
};
</script>
