<template>
  <!--main class="content main pr"-->
  <div class="container">
    <div class="pr-blocked__container">
      <div class="pr-blocked__icon-wrap">
        <svg class="icon-ban pr-blocked__icon">
          <use xlink:href="../assets/sprite.svg#ban"></use>
        </svg>
      </div>
      <div class="pr-blocked__content">
        <div class="pr-blocked__title-wrap">
          <svg class="icon-ban pr-blocked__icon">
            <use xlink:href="../assets/sprite.svg#ban"></use>
          </svg>
          <h1 class="pr-blocked__title">{{ description }}</h1>
        </div>
        <p class="pr-blocked__text">
          Если вы не согласны с этим, напишите на почту
          <a class="link" href="mailto:agency@mitroshina.org">agency@mitroshina.org</a>
        </p>
      </div>
    </div>
    <div v-if="showPr" class="pr-active__content has-pr">
      <!--h1 class="pr-prem-out__title">ВП на завтра</h1-->
      <div class="pr-events">
        <FutureMutualPR :mutualPrs="mutalPrNew" />
      </div>
      <div class="pr-events">
        <TodayMutualPR :mutualPrs="mutalPrToday" />
        <PastMutualPR :mutualPrs="mutalPrPast" />
      </div>
    </div>
  </div>
  <!--/main-->
</template>

<script>
import PastMutualPR from '@mp/search/components/SearchView/groups/PastMutualPR.vue';
import TodayMutualPR from '@mp/search/components/SearchView/groups/TodayMutualPR.vue';
import FutureMutualPR from '@mp/search/components/SearchView/groups/FutureMutualPR.vue';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    PastMutualPR,
    TodayMutualPR,
    FutureMutualPR,
  },
  props: {
    description: String,
  },
  computed: {
    ...mapState('mp_search', ['mutalPrToday', 'mutalPrPast', 'mutalPrNew']),
    showPr() {
      return (
        this.mutalPrToday.length > 0 || this.mutalPrPast.length > 0 || this.mutalPrNew.length > 0
      );
    },
  },
  methods: {
    ...mapActions('mp_search', ['updateStatus']),
  },
  async mounted() {
    await this.updateStatus();
  },
};
</script>

<style lang="css" scoped>
.pr-blocked__container {
  margin-top: 80px;
}
.link {
  color: #3897f0;
}
</style>
