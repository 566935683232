<template>
  <div>
    <extend-modal
      :title="title"
      :mainClass="mainClass"
      :wrapClass="wrapClass"
      :contentClass="contentClass"
      :description="desription"
      :show="showAuthModal"
      @hide="hide"
    >
      <verification
        v-if="stage === '2'"
        @next="verificationNextStep"
        @back="() => (currentStage = '1')"
      />
      <select-blogger-type v-if="stage === '1'" @close="hide" @next="() => (currentStage = '2')" />
      <settings
        @back="() => (currentStage = '2')"
        v-if="stage === '3'"
        @complete="() => (currentStage = '4')"
      />
      <premium-settings
        @back="() => (currentStage = '2')"
        v-if="stage === '3-1'"
        @next="() => (currentStage = '3-2')"
      />
      <premium-statistic
        @back="() => (currentStage = '3-1')"
        v-if="stage === '3-2'"
        @complete="() => (currentStage = '4')"
      />
      <email-confirmation v-if="stage === '4'" @complete="complete" @back="verificationNextStep" />
    </extend-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import ExtendModal from '@main/components/reusable/modals/ExtendModal.vue';
import Verification from './Verification.vue';
import SelectBloggerType from './SelectBloggerType.vue';
import Settings from './Settings.vue';
import PremiumSettings from './PremiumSettings.vue';
import PremiumStatistic from './PremiumStatistic.vue';
import EmailConfirmation from './EmailConfirmation.vue';

export default {
  components: {
    ExtendModal,
    Verification,
    SelectBloggerType,
    Settings,
    PremiumSettings,
    PremiumStatistic,
    EmailConfirmation,
  },
  data() {
    return {
      errorMessage: false,
      login: '',
      password: '',
      currentStage: '1',
    };
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapState('mutualpr', ['showAuthModal', 'premiumPrice']),
    stage() {
      return this.currentStage;
    },
    desription() {
      return `Шаг ${this.currentStage} из 4. ${this.descriptionTitle}`;
    },
    descriptionTitle() {
      switch (this.stage) {
        case '1':
          return 'Выбор типа аккаунта';
        case '3':
          return 'Охваты темы и тезисы';
        case '3-1':
          return 'Темы и тезисы';
        case '3-2':
          return 'Скриншоты';
        case '4':
          return 'Подтверждение e-mail';
        default:
          return 'Верификация аккаунта';
      }
    },
    title() {
      switch (this.stage) {
        case '1':
          return 'Выберите тип аккаунта';
        case '3':
          return 'Заполните темы, охваты и тезисы';
        case '3-1':
          return 'Заполните темы и тезисы';
        case '3-2':
          return 'Загрузите скриншоты статистики';
        case '4':
          return 'Подтвердите ваш e-mail адрес';
        default:
          return 'Верифицируйте аккаунт';
      }
    },
    mainClass() {
      switch (this.stage) {
        case '1':
          return 'popup-verify';
        case '3':
          return 'popup-verify';
        case '3-1':
          return 'popup-verify-prem';
        case '3-2':
          return 'popup-verify-prem';
        default:
          return 'popup-verify';
      }
    },
    wrapClass() {
      switch (this.stage) {
        case '1':
          return 'popup-verify';
        case '3':
          return 'popup-verify';
        case '3-1':
          return 'popup-verify-prem__wrap';
        case '3-2':
          return 'popup-verify-prem__wrap big';
        default:
          return 'popup-verify';
      }
    },
    contentClass() {
      switch (this.stage) {
        case '1':
          return 'popup-verify__content';
        case '3':
          return 'popup-verify__content';
        case '3-1':
          return 'popup-verify-prem__content';
        case '3-2':
          return 'popup-verify-prem__content';
        default:
          return 'popup-verify__content';
      }
    },
  },
  methods: {
    ...mapMutations('mutualpr', ['showingAuthModal', 'setAuthentication']),
    ...mapActions(['loadUser']),
    hide() {
      this.showingAuthModal(false);
      this.loadUser();
    },
    complete() {
      this.hide();
      this.setAuthentication(true);
    },
    verificationNextStep() {
      const reg = localStorage.getItem('regType');
      if (reg === 'showPremium') {
        this.currentStage = '3';
      } else if (reg === 'showDefault') {
        this.currentStage = '3-1';
      }
    },
  },

  watch: {
    currentStage(val) {
      localStorage.setItem(this.currentUser.profile.phone, val);
    },
    showAuthModal(val) {
      this.$bus.$emit('modal-open', val);
    },
  },

  async mounted() {
    const lsStage = localStorage.getItem(this.currentUser.profile.phone);
    if (lsStage) {
      this.currentStage = lsStage;
    }
  },
};
</script>

<style scoped>
.form-auth {
  padding: 2em;
}
.btn--login {
  margin-top: 2em;
}
.input-auth:nth-child(2n) {
  margin-top: 1em;
}
.login__error-msg {
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
  color: #ff0000;
}
.popup-on {
  padding-top: 40px;
}
.header__btn-login--main {
  padding: 8.5px 15px !important;
}
</style>
