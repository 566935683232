<template>
  <div>
    <p class="popup-verify-theme__title">Выберите ваш тип аккаунта</p>
    <div class="wrap__mg-bottom">
      <div class="blogger__filter-flex">
        <div
          v-for="(blogger, i) in bloggerTypes"
          :key="i"
          class="blogger__filter-item"
          :class="{ act: blogger.value === currentBloggerType }"
          @click="
            () => {
              currentBloggerType = blogger.value;
            }
          "
        >
          {{ blogger.text }}
        </div>
      </div>
    </div>
    <p v-if="allowCoverage && currentBloggerType" class="popup-verify-theme__title">
      Укажите ваше средние охваты в сторис
    </p>
    <div class="coverage-wrap__mg-bottom" v-if="allowCoverage">
      <div class="blogger__filter-flex">
        <div
          v-for="(cov, i) in coverageTypes"
          :key="i"
          :class="{ act: cov.value === currentAvgCoverage }"
          class="blogger__filter-item item--equal"
          @click="
            () => {
              currentAvgCoverage = cov.value;
            }
          "
        >
          {{ cov.text }}
        </div>
      </div>
    </div>
    <template v-else-if="!allowCoverage && currentBloggerType">
      <div>
        <p class="popup-write-partner__text popup-write-partner__text--bg wrong-type__warn">
          Наш сервис, к сожалению, не сможет подобрать вам пару для ВП, если вы являетесь коммерцией
          или пабликом.
          <br /><br />
          А если вы захотите выдать себя за блогера, то на этапе модерации вашу заявку в любом
          случае отклонят.
        </p>
      </div>
    </template>
    <template>
      <p v-if="allowCoverage" class="popup-write-partner__text popup-write-partner__text--bg">
        Если у вас меньше 1000 подписчиков, к сожалению, вы не сможете воспользоваться нашим
        сервисом.
      </p>
    </template>
    <div class="popup-verify__buttons">
      <button
        class="btn--border popup-verify__buttons-btn js-btnPopup"
        @click="() => $emit('close')"
      >
        Отмена
      </button>
      <button
        class="btn--orange popup-verify__buttons-btn js-btnPopup"
        :class="{ 'btn---disabled': disallowNext }"
        :disabled="disallowNext"
        @click="next"
      >
        Далее
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data: () => ({
    bloggerTypes: [
      { value: 'bloger', text: 'Блогер' },
      { value: 'commerce', text: 'Коммерция' },
      { value: 'public', text: 'Паблик' },
    ],
    coverageTypes: [
      { value: false, text: 'до 1000' },
      { value: true, text: 'больше 1000' },
    ],
    currentAvgCoverage: null,
    currentBloggerType: null,
  }),
  computed: {
    disallowNext() {
      return !this.currentBloggerType || !this.allowCoverage;
    },
    allowCoverage() {
      const val = this.currentBloggerType;
      return val !== 'commerce' && val !== 'public';
    },
  },
  methods: {
    ...mapActions(['loadUser']),
    async next() {
      if (this.currentAvgCoverage) {
        localStorage.setItem('regType', 'showPremium');
      } else {
        localStorage.setItem('regType', 'showDefault');
      }
      this.$emit('next');
    },
  },
};
</script>

<style scoped>
@media (max-width: 330px) {
  .blogger__filter-item {
    font-size: 13px !important;
  }
  .popup-write-partner__text {
    font-size: 14px !important;
  }
}
.blogger__filter-item {
  font-size: 14px !important;
  text-align: center;
  line-height: 11.5px !important;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
  cursor: pointer;
  padding: 11px 0px;
}
.blogger__filter-flex {
  border-radius: 5px !important;
  height: 35px !important;
  border: 1px solid #d5dfe4;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.blogger__filter-item.act {
  color: #fff;
  background-color: #3897f0;
}
.blogger__filter-item.act:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.blogger__filter-item.act:last-child {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.blogger__filter-item:not(:last-child) {
  border-right: 1px solid #d5dfe4;
}
.blogger__filter-item {
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}
.popup-write-partner__text {
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}
.wrap__mg-bottom {
  margin-bottom: 30px;
}
.coverage-wrap__mg-bottom {
  margin-bottom: 20px;
}
.btn--orange {
  width: calc(50% - 8px) !important;
  max-width: none !important;
}
.wrong-type__warn {
  font-size: 14px !important;
  line-height: 23px !important;
  margin-bottom: 55px;
}
.popup-verify-theme__title {
  font-size: 15px !important;
}
.item--equal {
  width: 50%;
}
.bottom__buttons {
  max-width: 125px;
  margin: 0 33px;
}
.btn---disabled {
  background: #b4bdc1;
  color: white;
}
.popup-write-partner__text {
  font-size: 15px;
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
