<template>
  <modal
    :show="showPriceModal"
    @hide="hide"
    title="Выберите тариф"
    mainClass="popup-new-service"
    wrapClass="popup-new-service__wrap"
  >
    <div class="popup__content popup-new-service__content">
      <h3 class="popup-new-service__title">Тарифы</h3>
      <template>
        <a
          @click="createPayment(price.id)"
          class="popup-new-service__item"
          v-for="(price, i) in discounted"
          :key="i"
        >
          <div class="popup-new-service__item-col">
            <span class="popup-new-service__item-title">{{ price.name }}</span>
            <p v-if="price.discounted" class="popup-new-service__item-total overline">
              {{ price.old_price }}
              <span class="popup-new-service__item-price discount"> -{{ price.percent }}% </span>
            </p>
            <p class="popup-new-service__item-total">
              {{ price.amount }} &#8381;
              <span class="popup-new-service__item-price">
                / {{ Math.round(price.amount / price.days) }} &#8381;
                <span class="small">в день</span>
              </span>
            </p>
          </div>
          <div class="popup-new-service__item-col">Оплатить</div>
        </a>
      </template>
      <p class="popup-new-service-result__text">
        В случае, если модератор отклонит вашу заявку на покупку премиум аккаунта, сервис
        автоматически вернет вам деньги. Цена в расчете за день указана за календарные дни.
      </p>
    </div>
  </modal>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import { CreatePremiumPayment } from '@mp/api/payments';

export default {
  components: {
    Modal,
  },
  data: () => ({}),
  computed: {
    ...mapState(['currentUser']),
    ...mapState('mutualpr', ['showPriceModal', 'premiumPrice', 'premiumOffers']),
    discounted() {
      const now = new Date();
      const offers = this.premiumOffers.filter((val) => new Date(val.expiration) - now > 0);
      const discounted = this.premiumPrice.map((val) => {
        const offer = offers.filter((off) => off.price.id === val.id)[0];
        if (offer) {
          const obj = { ...val };
          obj.percent = offer.percent;
          obj.old_price = obj.amount;
          obj.amount = Math.round(obj.amount - obj.amount * (offer.percent / 100));
          obj.discounted = true;
          return obj;
        }
        return val;
      });
      return discounted;
    },
  },
  methods: {
    ...mapMutations('mutualpr', ['hiddenPriceModal']),
    async createPayment(paymentId) {
      console.log('createPayment', paymentId);
      const response = await CreatePremiumPayment(paymentId, 'status=2300');
      console.log(response);
      if (response.url) {
        window.location = response.url;
      }
      this.hide();
    },
    hide() {
      this.hiddenPriceModal();
    },
  },
};
</script>

<style lang="css" scoped>
.overline {
  text-decoration: line-through;
  margin-bottom: 5px;
  color: gray;
}
.discount {
  margin-left: 5px;
}
@media (max-width: 330px;) {
  .popup-new-service__item-total {
    font-size: 19px !important;
  }
}
</style>
