<template>
  <div v-if="model">
    <p class="popup-verify-theme__title">Укажите 1-3 темы вашего аккаунта</p>
    <div class="dropdown popup-verify-theme__dropdown">
      <multiselect
        v-model="model.blogger_profile.tags"
        :options="tags"
        :multiple="true"
        placeholder="Выберите из списка"
        selectedLabel=""
        label="name"
        track-by="id"
        :max="3"
        selectLabel
        deselectLabel
      >
        <template v-slot:maxElements>Максимум 3!</template>
      </multiselect>
    </div>

    <p class="popup-verify-theme__title">Тезисы</p>
    <div
      class="popup-verify-prem__textarea textarea"
      :data-symbol="model.blogger_profile.theses.length + '/300'"
    >
      <textarea required v-model.trim="model.blogger_profile.theses" maxlength="300"></textarea>
      <div class="popup-verify-prem-exmpl">
        <div class="new-line">
          <p>Например: Мой блог про:</p>
          <p>Медитации и силу подсознания</p>
          <p>Психологическую сторону успеха и денег</p>
          <p>Обзоры и анти-обзоры на книги</p>
        </div>
        <div class="new-line">
          <p>Последние темы про:</p>
          <p>- Медитации для новичков</p>
          <p>- Как медитации влияют на мозг</p>
          <p>- Как подтолкнуть партнера к работе над собой</p>
          <p>- Как заставить кризисы работать на пользу</p>
          <p>- Как развиваться без книг и тренингов</p>
        </div>
      </div>
    </div>

    <div class="reg_sett-btnblock">
      <button class="btn--border" @click="() => $emit('back')">Назад</button>
      <button
        type="button"
        class="btn--orange popup-verify-prem__btn js-btnPopup"
        data-popup="popupVerifyStep7"
        @click="() => save()"
      >
        Далее
      </button>
    </div>
  </div>
</template>
<script>
import auth from '@main/api/auth';
import { mapState, mapMutations } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      model: null,
      stage: 1,
    };
  },
  computed: {
    ...mapState(['tags', 'currentUser']),
    enableNext() {
      return this.model.blogger_profile.tags.length > 0;
    },
  },
  methods: {
    async save() {
      const response = await auth.updateMe(this.model);
      if (response.success) {
        this.$emit('next');
      }
    },
  },
  mounted() {
    this.model = this.lodash.cloneDeep(this.currentUser);
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.btn--orange {
  margin-top: 0px;
  max-width: 157px;
  padding: 8.5px 15px;
}
.btn--border {
  max-width: 157px;
  height: 36px;
  padding: 8.5px 15px;
}
.reg_sett-btnblock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 36px;
}
</style>
