<template>
  <div @click="closeVpProfile" v-if="!loading">
    <div
      class="popup act popup-instruction"
      v-if="vpProfile && showProfileOverlay && vpProfileCoverageMatch"
    >
      <Card
        :storiesRequest="true"
        :account="vpProfile"
        @stories-request="() => (showProfileOverlay = false)"
        @stories-request-fail="() => (showProfileOverlay = false)"
      />
    </div>
    <Modal
      title="Несоответствие охватов!"
      :show="vpProfile && !vpProfileCoverageMatch && showProfileOverlay"
      @hide="closeVpProfile"
    >
      <div class="coverage__unmatch-content">
        К сожалению вы не можете отправить заявку на ВП данному пользователю, так как ваши охваты
        сторис не попадают в допустимый диапазон.
      </div>
    </Modal>
    <Modal title="Не найдено!" :show="!vpProfile && showProfileOverlay" @hide="closeVpProfile">
      <div class="coverage__unmatch-content">
        К сожалению вы не можете отправить заявку на ВП данному пользователю, профиля нет в системе
        либо он был удален
      </div>
    </Modal>
  </div>
  <div v-else class="popup act popup-instruction">
    <loader />
  </div>
</template>

<script>
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import bloggers from '@main/api/bloggers';
import { mapState, mapActions, mapMutations } from 'vuex';
import Modal from '@main/components/reusable/modals/Modal.vue';
import Loader from '@main/components/Loader';
import Card from '@mp/components/Card/Card.vue';

export default {
  components: {
    Modal,
    Card,
    Loader,
  },
  data: () => ({
    showProfileOverlay: false,
    vpProfile: null,
    loading: true,
  }),
  computed: {
    ...mapState(['currentUser']),
    vpProfileCoverageMatch() {
      if (!this.vpProfile) {
        return;
      }
      let min_cov = Math.round(this.vpProfile.avg_coverage - this.vpProfile.avg_coverage * 0.5);
      let max_cov = Math.round(this.vpProfile.avg_coverage + this.vpProfile.avg_coverage * 0.5);
      return max_cov >= this.currentUser.avg_coverage >= min_cov;
    },
  },
  methods: {
    ...mapMutations('mutualpr', ['showingAuthModal']),
    async checkVpFromStories(profileQuery) {
      if (profileQuery) {
        try {
          const response = await bloggers.getBlogger(profileQuery, this.$route.query.access);
          if (!response.error) {
            this.vpProfile = response;
          }
        } catch (e) {
          this.vpProfile = null;
        }
        this.showProfileOverlay = true;
      }
    },
    closeVpProfile() {
      this.showProfileOverlay = false;
      this.$router.push('/');
    },
  },
  async mounted() {
    if (this.currentUser) {
      this.loading = true;
      await this.checkVpFromStories(this.$route.query.profile);
      this.loading = false;
    } else {
      this.showingAuthModal(true);
    }
  },
};
</script>

<style lang="css" scoped></style>
