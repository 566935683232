<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import profileApi from '@main/api/auth';
import bloggersApi from '@main/api/bloggers';
import { getCountries } from '@main/api/info';

export default {
  data: () => ({
    model: null,
    audience: [
      {
        id: 1,
        name: '13-17',
      },
      {
        id: 2,
        name: '18-24',
      },
      {
        id: 3,
        name: '25-34',
      },
      {
        id: 4,
        name: '35-44',
      },
      {
        id: 5,
        name: '45-54',
      },
      {
        id: 6,
        name: '55-64',
      },
      {
        id: 7,
        name: '65+',
      },
    ],
    coverageScreen: null,
    countryScreen: null,
    audienceScreen: null,
    profileLoading: false,
    ageGroup: null,
  }),
  watch: {
    ageGroup(val) {
      console.log(val);
      this.model.blogger_profile.main_audience.age_group = val.id;
    },
  },
  computed: {
    ...mapState(['currentUser', 'tags', 'countries']),
    selectedThemesNames() {
      return this.model.blogger_profile.themes.map((obj) => obj.name);
    },
  },
  methods: {
    ...mapActions(['loadUser']),
    ...mapMutations('notifications', ['showMessage']),
    initAudience() {
      if (this.model.blogger_profile.main_audience != null) {
        this.ageGroup = this.audience.filter(
          (val) => this.model.blogger_profile.main_audience.age_group === val.id
        );
      }
    },
    async patchProfile(conf) {
      this.profileLoading = true;
      await profileApi.updateMe(this.model);
      await bloggersApi.uploadScreens(this.coverageScreen, this.countryScreen, this.audienceScreen);
      this.profileLoading = false;
      if (conf.showMessage) {
        this.showMessage({
          id: 500,
          type: 'basic',
          title: 'Настройки изменены',
          icon: 1,
          max_count: 1,
        });
      }
    },
    async initProfile() {
      await this.loadUser();
      this.model = JSON.parse(JSON.stringify(this.currentUser));
      this.initAudience();
    },
  },
};
</script>
