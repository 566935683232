<template>
  <div>
    <div>
      <p class="popup-verify__text">
        Для статистики необходимо загрузить подтверждающие скриншоты максимум недельной давности.
      </p>
      <div class="profile-sett__content-border">
        <p class="profile-sett__subtitle sett_sbtl">
          Охваты сторис
          <!-- <a href="#" class="link-blue profile-sett__subtitle-helper js-btnPopup" data-popup="popupThemeExmpl2">Пример заполнения</a> -->
        </p>
        <div class="coverage__container-flex">
          <div class="profile-sett__input">
            <span class="profile-sett__input-minmax">Минимум</span>
            <input
              type="number"
              v-model="model.blogger_profile.coverage_min"
              min="100"
              :max="model.blogger_profile.coverage_max"
              class="input profile-sett__input--small"
            />
          </div>
          <div class="profile-sett__input">
            <span class="profile-sett__input-minmax">Максимум</span>
            <input
              type="number"
              v-model="model.blogger_profile.coverage_max"
              max="2000000"
              :min="model.blogger_profile.coverage_min"
              class="input profile-sett__input--small"
            />
          </div>
          <div class="load-img btn--blue stat_file">
            <input
              type="file"
              class="load-img__input"
              accept="image/*"
              @change="(event) => handleFile(event, 'coverageScreen')"
            />
            <button class="load-img__btn profile-sett__btn-load">
              {{ coverageScreen ? 'Cкриншот выбран' : 'Загрузите скриншот' }}
            </button>
          </div>
        </div>
        <p v-for="(error, i) in coverageErrors" :key="i" class="red errors">{{ error }}</p>
      </div>
      <div class="profile-sett__content-border">
        <p class="profile-sett__subtitle">Какая аудитория вас интересует</p>
        <div class="profile-sett__checkbox">
          <div class="checkbox">
            <input id="country-checkbox1" type="checkbox" class="checkbox__input" />
            <label for="country-checkbox1" class="checkbox__label">Все регионы</label>
          </div>
          <div class="checkbox">
            <input id="country-checkbox2" type="checkbox" class="checkbox__input" />
            <label for="country-checkbox2" class="checkbox__label">Украина</label>
          </div>
          <div class="checkbox">
            <input id="country-checkbox3" type="checkbox" class="checkbox__input" />
            <label for="country-checkbox3" class="checkbox__label">Россия</label>
          </div>
          <div class="checkbox">
            <input id="country-checkbox4" type="checkbox" class="checkbox__input" />
            <label for="country-checkbox4" class="checkbox__label">Казахстан</label>
          </div>
        </div>
        <p class="profile-sett__text">
          Система будет делать приоритет на выбранную(-ые) <br />страну(-ы), если такие аккаунты
          будут в системе.
        </p>
      </div>
      <div class="profile-sett__content-border">
        <button
          type="button"
          @click="() => (showCountry = !showCountry)"
          class="profile-sett__subtitle link-blue js-profVerPremBtn"
        >
          Укажите страну и возраст <span>необязательно</span>
        </button>
        <p class="profile-sett__text">
          При указании страны и возраста, система будет делать приоритет на похожие по статистике
          аккаунты, если такие будут в системе.
        </p>
        <div class="popup-verify-prem__hidden" v-bind:class="{ act: showCountry }">
          <p class="profile-sett__subtitle">
            Преобладающая страна
            <!-- <a href="#" class="link-blue profile-sett__subtitle-helper">Пример</a> -->
          </p>
          <div class="profile-sett__content-flex profile-sett__content-flex--mgbottom">
            <div class="dropdown profile-sett__dropdown grid__select">
              <multiselect
                v-model="model.blogger_profile.main_country.country"
                :options="countries"
                placeholder=""
                selectLabel=""
                deselectLabel=""
                selectedLabel=""
                label="name"
                track-by="name"
              >
              </multiselect>
            </div>
            <div class="profile-sett__input-percent grid__input">
              <input
                type="number"
                class="input"
                v-model="model.blogger_profile.main_country.coverage"
                max="100"
              />
            </div>
            <div class="load-img btn--blue grid__button">
              <input
                type="file"
                class="load-img__input"
                accept="image/*"
                @change="(event) => handleFile(event, 'countryScreen')"
              />
              <button class="load-img__btn profile-sett__btn-load">
                {{ countryScreen ? 'Cкриншот выбран' : 'Загрузите скриншот' }}
              </button>
            </div>
          </div>
          <p class="profile-sett__subtitle">
            Преобладающий возраст
            <!-- <a href="#" class="link-blue profile-sett__subtitle-helper">Пример</a> -->
          </p>
          <div class="profile-sett__content-flex">
            <div
              class="dropdown profile-sett__dropdown profile-sett__dropdown-age grid__select half"
            >
              <multiselect
                v-model="ageGroup"
                :options="audience"
                placeholder=""
                selectLabel=""
                deselectLabel=""
                selectedLabel=""
                label="name"
                track-by="name"
              >
              </multiselect>
            </div>
            <div class="profile-sett__input-percent grid__input">
              <input
                type="number"
                class="input"
                max="100"
                v-model="model.blogger_profile.main_audience.age_group_percent"
              />
            </div>
            <div class="load-img btn--blue grid__button">
              <input
                type="file"
                class="load-img__input"
                accept="image/*"
                @change="(event) => handleFile(event, 'audienceScreen')"
              />
              <button class="load-img__btn profile-sett__btn-load">
                {{ audienceScreen ? 'Cкриншот выбран' : 'Загрузите скриншот' }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="prem__btn--wrapper">
        <button
          type="button"
          @click="submit"
          class="btn--orange"
          :disabled="!enableSubmit || profileLoading"
          data-popup="popupResultNewService"
        >
          Далее
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import ProfileCRUMixin from '@main/mixins/ProfileCRUMixin.vue';

export default {
  components: {
    Multiselect,
  },
  mixins: [ProfileCRUMixin],
  data: () => ({
    showCountry: false,
    coverageErrors: [],
  }),
  computed: {
    enableSubmit() {
      return (
        this.model.blogger_profile.coverage_min >= 100 &&
        this.model.blogger_profile.coverage_max >= 100 &&
        this.model.blogger_profile.coverage_max <= 2000000
      );
    },
  },
  methods: {
    async submit() {
      await this.patchProfile({
        showMessage: false,
      });
      this.$emit('complete');
    },
    handleFile(event, attr) {
      const file = event.target.files[0];
      if (file) {
        this.$set(this, attr, file);
      }
    },
  },
  async mounted() {
    this.initProfile();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.red .errors {
  margin-top: 15px;
  font-size: 13px;
  line-break: anywhere;
}
.btn--orange {
  max-width: none !important;
  margin-top: 14px;
  padding: 8.5px 15px;
  margin-bottom: 20px;
}
.link-blue {
  display: inline;
}
.prem__btn--wrapper {
  display: flex;
  flex-direction: row;
}
.stat_file {
  margin-bottom: 1px;
  height: 36px;
  max-height: none !important;
  max-width: none !important;
}
.profile-sett__content-flex {
  display: grid !important;
  grid-template:
    'input1 input2 button' 50px
    / 175px 45px auto;
  grid-column-gap: 10px;
}
.grid__button {
  grid-area: button;
  width: 100% !important;
  height: 36px !important;
  max-width: none !important;
}
.grid__select {
  grid-area: input1;
  width: 100%;
  max-width: 175px;
}
.grid__select .half {
  width: 60%;
}
.grid__input {
  grid-area: input2;
  margin-bottom: 4px !important;
}
.coverage__grid {
  display: grid;
  grid-template: 'input1 input2 button' / 85px 85px 140px;
}
.coverage__container-flex {
  display: flex !important;
  flex-direction: row;
  align-items: flex-end;
}
@media (max-width: 410px) {
  .profile-sett__content-flex {
    display: grid !important;
    grid-template:
      'input1 input2' 50px
      'button button' 50px
      / 180px 60px;
  }
  .grid__button {
    grid-area: button;
    width: 240px !important;
    height: 36px !important;
    max-width: none !important;
  }
}
</style>
