import apiService from '@main/api/index';
import { URL_PREFIX } from './index';

const CREATE_ABUSE_URL = `${URL_PREFIX}/api/moderation/create_abuse/`;
const GET_ABUSES_URL = `${URL_PREFIX}/api/moderation/abuses/`;
const GET_STAT_URL = `${URL_PREFIX}/api/moderation/abuses/`;
const GET_NEW_ACCOUNTS_URL = `${URL_PREFIX}/api/moderation/accounts/new/`;
const GET_ACCEPTED_ACCOUNTS_URL = `${URL_PREFIX}/api/moderation/accounts/accepted/`;
const GET_REJECTED_ACCOUNTS_URL = `${URL_PREFIX}/api/moderation/accounts/rejected/`;

/**
 * Создание жалобы
 * @param {number} profileId Идентификатор профиля
 * @param {string} mode Причина
 * @param {string} text Текст
 * @param {string} email почта
 * @param {file} files Скриншоты
 */
export async function createAbuse(profileId, mode, text, email, files) {
  const formData = new FormData();

  for (let i = 0; i < files.length; i += 1) {
    formData.append(`files[${i}]`, files[i]);
  }

  formData.append('reason', parseInt(mode, 10));
  formData.append('text', text);
  formData.append('email', email);
  formData.append('profile_id', profileId);

  await apiService.post(CREATE_ABUSE_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {
    success: true,
  };
}

/**
 * Получение жалоб
 */
export async function getAbuses() {
  const response = await apiService.get(GET_ABUSES_URL);
  return response.results;
}

/**
 * Получение новых аккаунтов для модерации
 */
export async function getNewAccounts() {
  const response = await apiService.get(GET_NEW_ACCOUNTS_URL);
  return response.results;
}

/**
 * Получение принятых аккаунтов
 */
export async function getAcceptedAccounts() {
  const response = await apiService.get(GET_ACCEPTED_ACCOUNTS_URL);
  return response.results;
}

/**
 * Получение отклоненных аккаунтов
 */
export async function getRejectedAccounts() {
  const response = await apiService.get(GET_REJECTED_ACCOUNTS_URL);
  return response.results;
}

/**
 * Подтверждение аккаунта
 */
export async function acceptedAccount(acoountId) {
  const response = await apiService.post(GET_ACCEPTED_ACCOUNTS_URL, { account_id: acoountId });
  return response;
}

/**
 * Отклонение аккаунта
 * @param {number} acoountId Идентификатор аккаунта
 */
export async function rejectedAccount(acoountId, reason) {
  const response = await apiService.post(GET_REJECTED_ACCOUNTS_URL, {
    account_id: acoountId,
    reason,
  });
  return response;
}

/**
 * Получение статистики акаунта
 * @param {number} acoountId Идентификатор аккаунта
 */
export async function getStatAccount(acoountId) {
  const response = await apiService.get(`${GET_STAT_URL}${acoountId}/stat/`);
  return response;
}

/**
 * Отклонение жалобы
 * @param {*} abuseId
 */
export async function rejectedAbuse(abuseId) {
  const response = await apiService.post(`${GET_ABUSES_URL}${abuseId}/rejected/`);
  return response;
}

/**
 * Создаение действия жалобы
 * @param {number} abuseId
 * @param {number} action
 */
export async function actionAbuse(abuseId, action) {
  const response = await apiService.post(`${GET_ABUSES_URL}${abuseId}/action/`, { action });
  return response;
}
