<template>
  <div>
    <div v-if="!sent">
      <p class="email_conf-text">
        Перейдите по ссылке из письма для подтверждения электронной почты.
      </p>
      <label for="email-conf"> Ваш e-mail </label>
      <input
        v-model="email"
        type="email"
        id="email-conf"
        placeholder="valianserintal@juarra.com"
        required="required"
        class="input popup-search-insta__input input-verif"
      />
      <p v-if="emailError" class="popup-verify__text error email">awdaw</p>
      <div class="email_conf-btn-group">
        <b-button variant="outline-default" @click="() => $emit('back')">Назад</b-button>
        <b-button
          variant="warning"
          :disabled="loading"
          @click="submit"
          class="btn--orange btn_confirm-mail"
        >
          Отправить подтверждение
        </b-button>
      </div>
    </div>
    <div v-else>
      <p class="email_conf-text">
        Отправлено подтверждение на почту перейдите на нее и подтвердите ваш адрес. Пример письма от
        easyprbot ниже.
      </p>
      <img class="email_confirm-img" src="../../../images/auth/email_confirm_example.png" />
      <a @click="() => (sent = false)" class="change_mail-lnk-btn">Изменить e-mail</a>
      <div class="email_conf-btn-group">
        <b-button
          variant="outline-default"
          @click="() => $emit('back')"
          class="btn--border btn_mail-conf"
          >Назад</b-button
        >
        <b-button
          variant="warning"
          @click="() => $emit('complete')"
          class="btn--orange btn_mail-conf"
          >Далее</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@main/api/auth';

export default {
  data: () => ({
    sent: false,
    email: '',
    emailError: false,
    loading: false,
  }),
  methods: {
    submit() {
      this.loading = true;
      auth
        .sendEmail(this.email)
        .then((res) => {
          if (res.success) {
            this.sent = true;
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.email_conf-text {
  font-size: 14px;
  margin-bottom: 15px;
}
.email_confirm-img {
  margin-bottom: 15px;
}
.email_conf-btn-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}
.change_mail-lnk-btn {
  margin-bottom: 25px;
  color: #3897f0;
}
.btn_confirm-mail {
  width: 72%;
  max-width: none;
}
@media .btn_mail-conf {
  width: 45%;
  max-width: none;
}
.email {
  text-align: right;
  font-size: 12.5px;
  color: red;
}
</style>
